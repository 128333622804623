import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Query from '../../Components/Query/Query'
import "./mainn.css";
import About from './About/About'
import Featured from './Featured/Featured'
import Contact from "./Contact/Contact.js"
import Newhero from "./Mainbanner/Newhero"
import Users from './Users/Users'
import { useState, useEffect } from 'react'
import Modal from "../../Components/Moda/Modal"
import Business from "./Business/Business.js"
const Mainn = () => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setModalOpen(true);
    }, 10000); // 5000 milliseconds = 5 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, []);


  return (
    <div>
 {modalOpen && <Modal setOpenModal={setModalOpen} />}
        <Navbar />
        <Newhero />
        <About />
        <Business />
        <Users />
        <Featured />
        <Contact />
        <Query />
        <Newsletter />
        <Footer />
    </div>
  )
}

export default Mainn
