import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Modall from '../../../Components/Modall/Modall';
import Modallerror from '../../../Components/Modall/Modallerror';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModalError(false);
  };

  const validationSchema = Yup.object({
    name: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
    phone: Yup.string().min(10, 'Must be at least 10 characters').required('Required'),
    email: Yup.string().email('Invalid email address') .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Invalid email address'
    ).required('Required'),
    message: Yup.string().required('Required'),
  });

  const sendEmail = (values, actions) => {
    const recaptchaValue = values['g-recaptcha-response'];
    if (!recaptchaValue) {
      // reCAPTCHA not validated
      actions.setSubmitting(false);
      return;
    }

    emailjs.sendForm('service_6cuir0f', 'contact-form', '4Y94BulGaL_N4yMfK')
      .then(
        (result) => {
          actions.setSubmitting(false);
          setShowModal(true);
          setTimeout(function () {
            window.location.assign('/thankyou');
          }, 2000);
        },
        (error) => {
          actions.setSubmitting(false);
          setShowModalError(true);
          setTimeout(function () {
            window.location.reload();
          }, 5000);
          console.log(error);
        }
      );



  };

  return (
    <div>
      {showModal ? <Modall closeModal={closeModal} /> : ''}
      {showModalError ? <Modallerror closeModal={closeModal} /> : ''}

      <section id="contact" className="contact mb-2">
        <div className="container">
          <div className="text-center" data-aos="fade-down">
            <h2 className="fw-semibold text-center">We have got your back! </h2>
            <p className="fw-semibold fs-14">
              Complete this form and we will get back to you in 24 hours.
            </p>
          </div>
          <div className="row mx-auto mt-0 mt-md-5">
            <div
              className="col-lg-8 mx-auto mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  message: '',
                  'g-recaptcha-response': '',
                }}
                validationSchema={validationSchema}
                onSubmit={sendEmail}
              >
                {(formikProps) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label htmlFor="name" className="fw-semibold">
                          Full Name
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-4 form-group mt-3 mt-md-0">
                        <label htmlFor="phone" className="fw-semibold">
                          Phone Number
                        </label>
                        <Field name="phone">
                          {({ field }) => (
                            <PhoneInput
                              className={`contact-phone ${
                                formikProps.touched.phone && formikProps.errors.phone
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              country={'us'}
                              value={field.value}
                              inputProps={{
                                name: field.name,
                                required: true,
                              }}
                              onChange={(value) => formikProps.setFieldValue('phone', value)}
                              onBlur={field.onBlur}
                              isValid={(value, country) => {
                                return value.trim().length >= 11; // Custom validation for 10-digit phone number
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-4 form-group mt-3 mt-md-0">
                        <label htmlFor="email" className="fw-semibold">
                          Email
                        </label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="message" className="fw-semibold">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        className="form-control"
                        name="message"
                        rows="5"
                        placeholder="Message"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="my-3"></div>
                    <div className="text-center">
                      <div className="g-recaptcha d-flex justify-content-center align-items-center mt-2 mb-2">
                        <ReCAPTCHA sitekey="6LfCyyEnAAAAAAznHFaBkK87hVn1RC2EvmUyJ6pE" />
                      </div>
                      <button
                        type="submit"
                        className="btn view-btn"
                        disabled={formikProps.isSubmitting}
                      >
                        {formikProps.isSubmitting ? 'Sending...' : 'Send Message'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
