import React, { useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import Modall from '../../Components/Modall/Modall';
import Modallerror from '../../Components/Modall/Modallerror';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Visitus = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [phone, setPhone] = useState('');

  const form = useRef();

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModalError(false);
  };

  const sendEmail = (values, event) => {
    const recaptchaValue = values['g-recaptcha-response'];
    if (!recaptchaValue) {
      // reCAPTCHA not validated
      return;
    }

    emailjs
      .sendForm('service_6cuir0f', 'contact-form', form.current, '4Y94BulGaL_N4yMfK')
      .then(
        (result) => {
          setTimeout(function () {
            window.location.assign('/thankyou');
          }, 1000);
          console.log(result)
        },
        (error) => {
          setShowModalError(true);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
          console.log(error);
        }
      );
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(3, 'First Name must be at least 3 characters')
      .required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
      .required('Email is required'),
    phone: Yup.string()
      .min(11, 'Phone number must be at least 11 digits')
      .required('Phone number is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  return (
    <div>
      {showModal ? <Modall closeModal={closeModal} /> : ''}
      {showModalError ? <Modallerror closeModal={closeModal} /> : ''}

      <section id="contact" className="contact inner-contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mt-1 mt-md-0 mb-lg-0 mb-3" data-aos="fade-right" data-aos-delay="200">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                  subject: '',
                  message: '',
                }}
                validationSchema={validationSchema}
                onSubmit={sendEmail}
              >
                {(formikProps) => (
                  <Form ref={form} className="email-form">
                    <div className="row">
                      <div className="col-md-4 form-group">
                        <Field
                          type="text"
                          name="firstName"
                          className={`form-control ${
                            formikProps.errors.firstName && formikProps.touched.firstName ? 'is-invalid' : ''
                          }`}
                          id="firstName"
                          placeholder="First Name"
                        />
                        <ErrorMessage name="firstName" component="div" className="text-danger" />
                      </div>
                      <div className="col-md-4 form-group">
                        <Field
                          type="text"
                          name="lastName"
                          className={`form-control ${
                            formikProps.errors.lastName && formikProps.touched.lastName ? 'is-invalid' : ''
                          }`}
                          id="lastName"
                          placeholder="Last Name"
                        />
                        <ErrorMessage name="lastName" component="div" className="text-danger" />
                      </div>

                      <div className="col-md-4 form-group mt-3 mt-md-0">
                        <Field name="phone">
                          {({ field }) => (
                            <PhoneInput
                              className="contact-phone"
                              country={'us'}
                              value={field.value}
                              inputProps={{
                                name: field.name,
                                required: true,
                              }}
                              onChange={(value) => formikProps.setFieldValue('phone', value)}
                              onBlur={field.onBlur}
                              isValid={(value, country) => {
                                return value.trim().length >= 11; // Custom validation for 10-digit phone number
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="phone" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <Field
                          type="email"
                          className={`form-control mt-3 ${
                            formikProps.errors.email && formikProps.touched.email ? 'is-invalid' : ''
                          }`}
                          name="email"
                          id="email"
                          placeholder="Your Email"
                        />
                        <ErrorMessage name="email" component="div" className="text-danger" />
                      </div>

                      <div className="col-md-6 form-group mt-3">
                        <Field
                          type="text"
                          className={`form-control ${
                            formikProps.errors.subject && formikProps.touched.subject ? 'is-invalid' : ''
                          }`}
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                        />
                        <ErrorMessage name="subject" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="form-group mt-3">
                      <Field
                        as="textarea"
                        className={`form-control ${
                          formikProps.errors.message && formikProps.touched.message ? 'is-invalid' : ''
                        }`}
                        name="message"
                        rows="5"
                        placeholder="Message"
                      />
                      <ErrorMessage name="message" component="div" className="text-danger" />
                    </div>

                    <div className="g-recaptcha d-flex justify-content-center align-items-center mt-2 mb-2">
                      <ReCAPTCHA sitekey="6LfCyyEnAAAAAAznHFaBkK87hVn1RC2EvmUyJ6pE" />
                    </div>

                    <div className="text-center">
                      <button className="btn view-btn" type="submit">
                        Send Message
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="col-lg-4 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="100">
              <div className="info">
                <div className="address">
                  <i className="fa fa-map"></i>
                  <h4>Location:</h4>
                  <p className="text-dark">2160 Barranca Parkway, # 1014, Irvine, CA 92606</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Visitus;
