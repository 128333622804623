import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Hero from './Hero'
import Footer from '../../Components/Footer/Footer'
import Newsletter from '../../Components/Newsletter/Newsletter'
import "./contact.css";
import Getintouch from './Getintouch'
import Visitus from './Visitus'
import Map from "./Map"
import Query from '../../Components/Query/Query'
const Contact = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <Getintouch />
            <Visitus />
            <Map />
            <Query />
            <Newsletter />
            <Footer />
        </div>
    )
}

export default Contact