import React from 'react'

import { Icon } from '@iconify/react';

import mobile_image from "../../../assets/img/mobile-demo2.png"
const About = () => {
  return (
    <div>
        <section id="about" className="about mb-sm-3  mt-sm-3">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 icon-boxes d-flex flex-column justify-content-center py-5" data-aos="fade-left">
            <h1 className="fw-bold fs-3 word-new-text">How are we helping accident victims?</h1>
            <div className="row">
              <div className="col-lg-6 col-md-4">
                <div className="icon-box d-flex flex-column align-items-center helping-box" data-aos="zoom-in" data-aos-delay="100">
                  <div className=""><Icon icon="mdi:person-star" color="#008bd0" width="48" height="48" /></div>
                  <h5 className="title ">Specialized Injury Physicians & Attorneys near you</h5>
                  <p className="description">Find a doctor/attorney from our database</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="icon-box d-flex flex-column align-items-center helping-box" data-aos="zoom-in" data-aos-delay="200">
                  <div className=""><Icon icon="mdi:dollar-off" color="#008bd0" width="48" height="48" />                  </div>
                  <h5 className="title">  No costs until settlement </h5>
                  <p className="description">No out of pocket costs $$</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="icon-box d-flex flex-column align-items-center helping-box" data-aos="zoom-in" data-aos-delay="300">
                  <div className=""><Icon icon="bx:support" color="#008bd0" width="48" height="48" />

                  </div>
                  <h5 className="title">Support Services</h5>
                  <p className="description">Towing, Body Shop and Rental cars</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="icon-box d-flex flex-column align-items-center helping-box" data-aos="zoom-in" data-aos-delay="400">
                  <div className=""><Icon icon="covid:transmission-virus-mobile-application" color="#008bd0" width="48" height="48" />
                  </div>
                  <h5 className="title">Mobile Client App available</h5>
                  <p className="description">Sign documents, confirm appointments, upload accident photos & more</p>
                </div>
              </div>
             
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align-items-center" data-aos="fade-right">
            <img src={mobile_image} className="img-fluid " alt="mobile_image" />
          </div>
        </div>

      </div>
    </section>
    </div>
  )
}

export default About