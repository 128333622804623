import React, { useEffect } from 'react'
import { Icon } from '@iconify/react';
import "./landingpage.css";
import LandingFOrm from './LandingFOrm';
import computer from "../../assets/img/landing/computer.png"
import MyFormComponent from './MyFormComponent';
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo.png"
const LandingPage = () => {

    return (
        <>
        <div className='landingpageback'>
            <div className='container'>
                <div className='row pt-3'>
                    <div className=' col-md-7  '>
                        <div className='landing-icons'>
                            {/* <Icon icon="logos:facebook" width="36" height="36" />
                            <Icon icon="entypo-social:linkedin-with-circle" color="#fff" width="36" height="36" /> */}
                           <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FAccidentMate"> <Icon icon="ant-design:twitter-circle-filled" color="#fff" width="36" height="36" /> </Link>
                           <Link to="https://www.youtube.com/@AccidentMATE"> <Icon className='youtube-icon' icon="ri:youtube-fill" color="white" width="24" height="24" /></Link>
                        </div>
                        
                    </div>

                    <div className='col-md-5 text-end'>
                    <img className='landing-logo' src={Logo} alt='Accident-Mate-LOGO'></img>
                        </div>
                </div>

                <div className='row'>
                    <div className='col-lg-7 col-md-6'>
                        <div className='landing-main'>

                     
                            <h2>
                            Get the Support You Need After an Accident

                            </h2>
                          
                            <span>
                            AccidentMATE is your trusted partner in navigating the complex world of insurance claims and legal processes, ensuring you receive the maximum compensation you deserve.
                            </span>

                            <p><span className='free-consult'>Free Consultation:</span> +1-888-982-1882</p>
                        </div>
                    </div>

                    <div className='col-lg-5 col-md-6'>
                        
                    <MyFormComponent />
                    </div>
                </div>

             

                <div className='row mt-3'>
                   <div className='col-lg-12 col-sm-12 commondisplayflex'>

                    {/* <img src={computer} alt="computer-img" className='img-fluid'></img> */}
                    <h4 className='text-center help-section'>We are here to help!</h4>
                   </div>
                </div>


            </div>


        </div>


        <div className='landingpagecontent'>
        <div className='container'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className="content">
            <Icon className='content-icon' icon="gg:template" color="white" width="48" height="48" />
                    <h4>
                    Expert Guidance at Your Fingertips

                    </h4>
                  <span>
                  AccidentMATE gives you access to a team of experienced accident support specialists ready to provide a free consultation. They will guide you through every step of the process, answering your questions and offering personalized advice tailored to your unique situation.
                    </span>
                    </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className="content">
                <Icon className='content-icon' icon="ant-design:insurance-outlined" color="white" width="48" height="48" />
                    <h4>
                    Understanding Your Insurance Coverage

                    </h4>
                  <span>
                  We understand that insurance policies can be confusing. Our experts will help you decipher the fine print and ensure you fully understand your coverage. We'll work with you to identify potential gaps or limitations in your policy so that you can make informed decisions about your claim.
                    </span>
                    </div>
                </div>
        </div>

        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className="content">
            <Icon className='content-icon' icon="solar:medical-kit-broken" color="white" width="48" height="48" />
                    <h4>
                    Navigating Medical and Legal Services


                    </h4>
                  <span>
                  Accidentmate.com is here to support you with insurance matters and finding the right medical and legal professionals. We have a network of trusted providers who specialize in accident-related cases. We'll connect you with professionals who can help maximize your claim and ensure that your rights are protected.
                    </span>
                    </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className="content">
            <Icon className='content-icon' icon="mdi:legal" color="white" width="48" height="48" />
                    <h4>
                    Your Maximum Claim, Our Priority
                    </h4>
                  <span>
                  We are dedicated to helping accident victims like yourself receive the maximum compensation possible. Our team will work tirelessly on your behalf, leveraging our expertise and resources to build a strong case that supports your claim for damages.
                    </span>
                    </div>
                </div>
        </div>
        <div className='row'>
        <Link className='btn' to="/">Visit Website</Link>
        </div>
        </div>
        </div>
        </>
    )
}

export default LandingPage
