import React, { useState } from 'react';
import "./query.css";
import Phone_icon from "../../assets/img/queryicon.png";
import { Icon } from '@iconify/react';
import emailjs from '@emailjs/browser';
import Avatar_image from "../../assets/img/man.png";
import Modall from "../../Components/Modall/Modall";
import Modallerror from "../../Components/Modall/Modallerror";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Query = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .required('Name is required'),
    phone: Yup.string()
      .min(10, 'Phone number must be at least 10 digits')
      .required('Phone number is required'),
    email: Yup.string()
      .email('Invalid email address')  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
      .required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  const closeModal = () => {
    setShowModal(false);
    setShowModalError(false);
  };


  const handleSubmit = (values, { setSubmitting }) => {
    emailjs.send('service_6cuir0f', 'hover-form', values, 'YOUR_USER_ID')
      .then((result) => {
        setTimeout(function () {
          window.location.assign("/thankyou");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setShowModalError(true);
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  function myFunction() {
    document.getElementById("deployform").classList.add('open');
  }

  function myFunction1() {
    document.getElementById("deployform").classList.remove('open');
  }

  return (
    <div>
      {showModal && <Modall closeModal={closeModal} />}
      {showModalError && <Modallerror closeModal={closeModal} />}

      <div></div>
      <section id="query">
        <div id="deployform" className="deployform">
          <img src={Phone_icon} alt="Phone_icon" className="icon" onClick={myFunction} />
          <i className="fa fa-times cancel" onClick={myFunction1} aria-hidden="true"></i>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <span> <img src={Avatar_image} />Hi, There</span>
                <Field
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="input-field"
                />
                <ErrorMessage name="name" component="div" className="text-danger text-center" />

                <PhoneInput
                  className="input-field newPhone"
                  country={'us'}
                  value={values.phone}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  onChange={(value) => setFieldValue('phone', value)}
                />
                <ErrorMessage name="phone" component="div" className="text-danger text-center" />

                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="input-field"
                />
                <ErrorMessage name="email" component="div" className="text-danger text-center" />

                <Field
                  as="textarea"
                  name="message"
                  placeholder="Your message"
                  className="input-field"
                />
                <ErrorMessage name="message" component="div" className="text-danger text-center" />

                <button type="submit" className="btn1" disabled={isSubmitting}>
                  Talk to us today
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default Query;
