import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Footer from '../../Components/Footer/Footer'
import Privacy from './Privacy'

import Query from '../../Components/Query/Query'

const TANDC = () => {
  return (
    <div>
        <Navbar />
      
        <Privacy />
        <Query />
        <Newsletter />
        <Footer />
    </div>
  )
}

export default TANDC